<template>
    <div class="result" v-bind:id="item.mediaId" @click="showDetails()">
        <div v-if="item.readerId" class="ausleihe">Ausgeliehen! Voraussichtlich bis zum {{ rueckgabeDatum }}</div>
        <div v-if="item.availableId == 5 || item.availableId == 19" class="ausleihe">In Bearbeitung</div>
        <div class="header">
            <div v-if="item.title != ''">
                <div class="titel">
                    <i v-if="item.availableId == 1 || item.availableId == 17 || item.availableId == 19" class="fa-solid fa-circle"></i> 
                    {{ item.title }} 
                </div>
                <div v-if="item.availableId == 17" class="mt-10">
                    Zeitschrift<span v-if="item.periodicaLocation != ''">: {{ item.periodicaLocation }}</span> 
                </div>
            </div>
            <div v-if="item.subTitle != ''" class="untertitel">{{ item.subTitle }}</div>
            <div v-if="item.author != ''" class="autor">{{ displayAutor }}</div>
            <div v-if="item.year != ''" class="jahr mt-5">Erscheinungsdatum: {{ item.year }}</div>
            <div v-if="item.edition != ''" class="auflage">Auflage: {{ item.edition }}</div>
            <div v-if="item.availableId != 1 && item.availableId != 17 && item.availableId != 19" class="borrowStats">Beliebtheit: {{ item.borrowStats }}x ausgeliehen</div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'SearchResult',
    props: {
        item: Object
    },
    created() {
        this.moment = moment;
    },
    methods: {
        showDetails() {
            this.$router.push({ name: 'media', params: {mediaId: this.item.mediaId } })
        }
    },
    computed: {
        rueckgabeDatum: function () {
            return moment(this.item.return.split("T").slice(0,1).join("")).format('DD.MM.YYYY')
        },
        displayAutor: function() {
            let arr = this.item.author.split(";");
            if (arr.length <= 1) {
                return arr.join("").split(",").reverse().join(" ");
            }
            
            // reverse any author name for multiple entrys
            arr = arr.map((val) => {
                return val.split(",").reverse().join(" ");
            });
            return arr.join("; ");
        }
    }
}
</script>

<style lang="scss" scoped>
.result {
   min-width: 300px;
   
   margin-bottom: 10px;
   padding: 10px;
   
   border: $border;
   background-color: $bgColor;
   
   cursor: pointer;
}

.header {
    font-weight: 400;
    font-size: 13px;
    .titel {
        font-size: 18px;
        .fa-circle {
            color: $btnBgColorRed;
            font-size: 15px;
        }
    }
}

.ausleihe {
    color: $btnBgColorRed;
    padding: 0 5px;
    text-align: center;
}
</style>