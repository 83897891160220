<template>
    <div class="result" v-if="!!media" v-bind:id="media.mediaId">
        <div class="header">
            <div v-if="media.title != ''">
                <div class="titel">
                    <h1><i>{{ media.title }}</i></h1>
                </div>
            </div>
            <div v-if="!!media.subTitle && media.subTitle != ''" class="untertitel">{{ media.subTitle }}</div>
            <div v-if="!!media.author && media.author != ''" class="autor mt-10">
                <span @click="linkAuthor(autor)" v-for="(autor, index) in displayAutor" :key="index">
                    <br>
                    {{ autor }}
                </span>
                
            </div>
        </div>
        <div class="details mt-15">
            
            <div class="medien-art mt-5">
                <div class="title">Medienart</div>
                <div v-if="media.availableId == 17" class="val">Zeitschrift<span v-if="media.periodicaLocation != ''"> ({{ media.periodicaLocation }})</span></div>
                <div v-if="media.availableId == 33" class="val">Kleinschrift</div>
                <div v-if="media.availableId != 17" class="val">Buch</div>
            </div>

            <div class="nichtVfBz mt-5">
                <div class="title">Status</div>
                <div class="val">
                    <div v-if="!media.availableId && media.readerId == ''">
                        <span class="available-media">Verfügbar</span>
                    </div>
                    <div v-else-if="media.availableId != 5 && media.availableId != 19 && media.readerId == ''">
                        <!-- Präsenzbestand -->
                        <i v-if="media.availableId == 1 || media.availableId == 17" class="fa-solid fa-circle"></i> <span>{{ media.availableDesc }}</span>
                    </div>
                    <div v-else-if="(media.availableId == 5 || media.availableId == 19) && media.readerId == ''">
                        <!-- Bearbeitungsmode -->
                        <span class="warn">In Bearbeitung</span>
                    </div>
                    <div v-else-if="media.readerId !== '' && media.return !== ''">
                        <span class="warn">
                            Ausgeliehen! Voraussichtlich bis zum {{ rueckgabeDatum }}
                        </span>
                    </div>                    
                </div>
            </div>
            <div v-if="!!media.signature && media.signature != ''" class="signatur mt-10">
                <div class="title">Signatur</div>
                <div class="val">{{ media.signature}}</div>
            </div>

            <div v-if="media.availableId != 1 && media.availableId != 17 && media.availableId != 19" class="borrowStats mt-5">
                <div class="title">Beliebtheit</div>
                <div class="val">{{ media.borrowStats }}x ausgeliehen</div>
            </div>

            <div v-if="!!media.year && media.year != ''" class="jahr mt-5">
                <div class="title">Erscheinungsdatum</div>
                <div class="val">{{ media.year }}</div>
            </div>
            
            <div v-if="!!media.edition && media.edition != ''" class="auflage mt-5">
                <div class="title">Auflage</div>
                <div class="val">{{ media.edition }}</div>
            </div>
            
            <div v-if="!!media.editor && media.editor != ''" class="herausgeber mt-5">
                <div class="title">Herausgeber</div>
                <div class="val">{{ media.editor }}</div>
            </div>

            <div v-if="!!media.publisher && media.publisher != ''" class="verlag mt-5">
                <div class="title">Verlag</div>
                <div class="val">{{ media.publisher}}, {{media.pubLocation }}</div>
            </div>

            <div v-if="!!media.series && media.series != ''" class="reihe mt-5">
                <div class="title">Reihe</div>
                <div class="val">{{ media.series }}</div>
            </div>
                    
            <div v-if="!!media.volume && media.volume != ''" class="band mt-5">
                <div class="title">Band</div>
                <div class="val">{{ media.volume }}</div>
            </div>

            <div v-if="!!media.pages && media.pages != ''" class="seitenumfang mt-5">
                <div class="title">Umfang</div>
                <div class="val">{{ media.pages }}</div>
            </div>

            <div v-if="!!media.keywords" class="schlagwort mt-5">
                <div class="title">Schlagworte</div>
                <div class="val">
                    <span class="keyword" v-for="(keyword, index) in media.keywords" :key="index">
                        <Keyword :keyword="keyword" />
                    </span>
                </div>
            </div>
        </div>
        <div class="fab-container">
            <button @click="this.$router.go(-1)" class="button iconbutton btn-color">
                <i class="fa-solid fa-chevron-left"></i>
            </button>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import Keyword from '@/components/Keyword'

export default {
    name: 'SearchResult',
    components: {
        Keyword
    },
    created() {
        this.moment = moment
    },
    methods: {
        linkAuthor(autor) {
            let author = []
            let authorArray = this.media.author.split(";");
            author = authorArray.map((val) => {
                if (autor == val.split(",").reverse().join(" ")) {
                    return val.trim()
                }
            });
            author = author.join("")
            this.$router.push({ name: 'author', params: {author: author} })
        }
    },
    computed: {
        media: function() {
            return this.$store.state.results[0]
        },
        rueckgabeDatum: function () {
            return moment(this.media.return.split("T").slice(0,1).join("")).format('DD.MM.YYYY')
        },
        displayAutor: function() {
            let arr = this.media.author.split(";");
            if (arr.length <= 1) {
                return [arr.join("").split(",").reverse().join(" ")]
            }
            
            // reverse any author name for multiple entrys
            arr = arr.map((val) => {
                return val.split(",").reverse().join(" ");
            });
            return arr;
        }
    }
}
</script>

<style lang="scss" scoped>
.result {
   margin-bottom: 10px;
   padding: 10px;
   font-weight: 400;
}

// Desktop
.header {
    .titel {
        h1 {
            font-size: 3.5rem;
            line-height: 1.2em;
            text-transform: uppercase;
            margin-top: 5rem;
            word-wrap: break-word;
        }
    }
    .untertitel {
        font-size: 1.5rem;
        line-height: 1.2em;
        margin-top: .2rem;
    }
    .autor {
        text-decoration: underline;
        cursor: pointer;
    }
}

.details {
    margin-left: 5px;
    .title {
        font-size: 1rem;
        font-weight: 600;
    }

    .nichtVfBz {
        .fa-circle {
            color: $btnBgColorRed;
            font-size: 15px;
        }
    }
    
    .warn {
        background-color: $warnColorRed;
        color: #fff;
        padding: 2px 5px;
        text-align: center;
    }

    .available-media {
        background-color: $availableColorGreen;
        color: #fff;
        padding: 2px 5px;
        text-align: center;
    }

    .schlagwort {
        .val {
            display: flex;
            flex-wrap: wrap;
        }
        .keyword {
            padding: 5px;
            margin-right: 10px;
            margin-top: 10px;
            border: $border;
            background-color: $bgColor;
            cursor: pointer;
            word-wrap: break-word;
        }
    }
}

// Mobile
@media (max-width: 900px) {
  .header {
        .titel {
            h1 {
                font-size: 2.8rem;
                margin-top: 0;
            }
        }
    }
}

</style>