<template>
    <div>
        <div v-if="showResults || !showKeywords">
            <div class="search-bar">
                <input class="btn-block" type="text" v-model="searchData.fullText" name="fullText" placeholder="Suchen..." @keyup.enter="searchMedia()">
                <button class="btn btn-color" @click="searchMedia()"><i class="fa-solid fa-magnifying-glass"></i></button>
            </div>
            <div class="filter-bar">
                <button class="btn filter btn-color" @click="toggleFilter()"><i class="fa-solid fa-filter"></i> Filter</button>
                <button class="btn reset btn-color" @click="resetSearch()"><i class="fa-solid fa-trash-can"></i> Zurücksetzen</button>
            </div>
            <div v-if="filter" class="search-filter">
                <div>
                    <button class="btn btn-color" @click="listAllKeyWords()"><i class="fa-solid fa-list"></i> Alle Schlagworte anzeigen </button>
                </div>
            </div>
        </div>
        <div v-if="showKeywords">
            <div class="search-bar">
                <input class="btn-block" type="text" v-model="keyword" name="keyword" placeholder="Nach Schlagwort suchen..." @keyup.enter="searchKeyword()">
                <button class="btn btn-color" @click="searchKeyword()"><i class="fa fa-search"></i></button>
            </div>
            <div class="filter-bar">
                <button class="btn reset btn-color" @click="resetSearch()"><i class="fa-solid fa-trash-can"></i> Zurücksetzen</button>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'SearchForm',
    data() {
        return {
            searchData: {
                fullText: this.$route.params.query ? this.$route.params.query : "",
                Schlagwort: this.$route.params.keyword ? this.$route.params.keyword : "",
                Autor: this.$route.params.author ? this.$route.params.author : "",
            },
            keyword: "",
            filter: false,
        }
    },
    mounted() {
        // check at mounted if link has query
        if (this.$route.params.query || this.$route.params.author) {
            //console.log("calling searchForm:mounted()", this.$route.params)
            this.searchMedia();  
        } 
    },
    methods: {
        searchMedia() {
            if (this.searchData.fullText != ""
                || this.searchData.Schlagwort != ""
                || this.searchData.Autor != "") {
                
                if (this.searchData.fullText != "") {
                    this.$router.push({ name: 'search', params: {query: this.searchData.fullText } })
                    this.searchData.Schlagwort = ""
                } else if (this.searchData.Schlagwort != "") {
                    this.$router.push({ name: 'keywordSearch', params: {keyword: this.searchData.Schlagwort } })
                }
                //console.log("search by params in SearchForm.vue:searchMedia()")
                //console.log("SEARCH DATA", this.searchData)
                this.$store.dispatch('searchByParams', this.searchData);
                this.$store.state.keywords = [];
                this.filter = false;
            }
        },
        searchKeyword() {
            // Sucht in der Schlagwortliste ein bestimmtes Wort und wird darauf reduziert
            this.$emit('search-keyword', this.keyword);
        },
        resetSearch() {
            this.searchData = {};
            this.resetKeyword();
            this.filter = false;
            this.$store.state.results = [];
            this.$store.state.keywords = [];
        },
        toggleFilter() {
            // Reset fullText search to enable filter search
            this.searchData.fullText = "";
            this.$router.push({ name: 'start'});
            this.filter = !this.filter; 
        },
        listAllKeyWords() {
            this.$store.dispatch('getKeywords');
            this.$store.state.results = [];
            this.resetKeyword();
            this.toggleFilter();
        },
        resetKeyword() {
            this.keyword = "";
            this.$emit('search-keyword', "");
        }
    },
    computed: {
        showResults: function() {
            return this.$store.getters.showResults;
        },
        showKeywords: function() {
            return this.$store.getters.showKeywords;
        },
        
    }
}
</script>

<style lang="scss" scoped>
input {
    border-style: none; 
    background: transparent; 
    outline: none; 
    height: 50px;
    padding: 0 55px 0 10px;
    color: #222;
    font-size: 15px;
    line-height: 1.2;
    border: $border;
    text-align: left;
    background-color: $bgColor;
}

.search-bar {
    .btn {
        float: right;
        height: 50px;
        width: 50px;
        text-align: center;
        position: relative;
        top: -50px;
    }
}

.filter-bar {
    .btn {
        margin-right: 15px;
        margin-top: 15px;
    }
}

.search-filter {
    margin-top: 15px;
    input {
        margin-top: 15px;
    }
}

.divider-box {
    display: flex;
    justify-content: center;
    align-items: center;

}
.divider-sig {
    background-color: #222;
    height: 2px;
    width: 50px;
    margin: 5px;
}
</style>