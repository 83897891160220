<template>
  <router-view />
  <footer>
    <a href="https://www.bfp-ausbildung.de/impressum" target="_blank" class="footer-link">Impressum</a>
    <a href="https://www.bfp-ausbildung.de/datenschutz" target="_blank" class="footer-link">Datenschutz</a>
  </footer>
</template>

<script>

</script>

<style lang="scss" scoped>
  footer {
    position: relative;
    padding: 10px 20px;
    background: #fff;
    
    :first-child {
      border: 0;
      padding-left: 0;
    }
    
    a {
      color: #333;
      padding: 0 10px;
      border-left: 1px solid #333;
    }
  }
</style>
