<template>
    <div class="result-list">
        <div v-if="loading" class="loading">
            <div class="lds-dual-ring"></div>
        </div>
        <div v-else>
            <div v-if="showResults && results.length >= 1" class="results">
                <div v-for="item in results" :key="item.mediaId" :id="item.mediaId">
                    <SearchResult :item="item" />
                </div>
            </div>
            <div v-else-if="showKeywords && keywords.length >= 1" class="keywords">
                <div v-for="(keyword, index) in keywords" :key="index">
                    <div class="keyword">
                        <Keyword :keyword="keyword" />
                    </div>
                </div>
            </div>
            <div v-else> Keine Ergebnisse gefunden. </div>
        </div>
    </div>
</template>

<script>
import SearchResult from '@/components/SearchResult'
import Keyword from '@/components/Keyword'

export default {
    name: 'SearchResultList',
    components: {
        SearchResult,
        Keyword
    },
    props: {
        filteredKeyword: String        
    },
    computed: {
        results: function() {
            return this.$store.state.results;
        },
        keywords: function() {
            // Grenzt die Schlagwortliste aus der DB auf das gesuchte Schlagwort ein
            if (this.filteredKeyword != "") {
                let arrKeywords = this.$store.state.keywords;
                // prüft, ob das gesuchte Wort mit schlagworten im Array matcht
                // Regex ist LIKE in mysql
                let re = new RegExp(".*" + this.filteredKeyword + ".*");
                var tempKeywordsResult = arrKeywords.filter((item) => {
                    return item.match(re)
                });
                return tempKeywordsResult;
            }
            // Wenn nicht nach einem bestimmten Schlagwort gesucht wurde, werden alle Schlagwörter ausgegeben
            return this.$store.state.keywords;
        },
        loading: function() {
            return this.$store.state.loading;
        },
        showResults: function() {
            return this.$store.getters.showResults;
        },
        showKeywords: function() {
            return this.$store.getters.showKeywords;
        },
    }
}
</script>

<style lang="scss" scoped>
.result-list {
    margin-top: 15px;
    margin-bottom: 55px;
}

/* LOADING SPINNER */
.loading {
    display: flex;
    justify-content: center;
    height: 300px;
    align-items: center;
    margin-left: -15px;
}

.lds-dual-ring {
  display: inline-block;
  width: 30px;
  height: 30px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 34px;
  height: 34px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-color: #ccc transparent #ccc transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.keyword {
    min-width: 300px;

    margin-bottom: 10px;
    padding: 10px;

    border: $border;
    background-color: $bgColor;

    cursor: pointer;

    a {
        color: #222;
    }
}

</style>