import { createWebHashHistory, createRouter } from "vue-router"
import Start from "../components/Start.vue"
import ResultDetail from "../components/ResultDetail.vue"
import store from '../store'

const routes = [
    {
        path: "/",
        name: "start",
        component: Start,
    },
    {
        path: "/search/:query",
        name: "search",
        component: Start,
    },
    {
        path: "/search/author/:author",
        name: "author",
        component: Start,
    },
    {
        path: "/search/keyword/:keyword",
        name: "keywordSearch",
        component: Start,
    },
    {
        path: "/media/:mediaId",
        name: "media",
        component: ResultDetail,
    },
    {  
        // Error handling instead 404 show search
        path: "/:catchAll(.*)",
        component: Start,
    },
]
  
const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

router.beforeEach(async (to, from) => {
    // react to route changes...
    if (to.name == "media") {
        //console.log("ROUTER MEDIA")
        store.dispatch('mediaById', {"mediaId": to.params.mediaId})
    } else if (to.name == "keywordSearch") {
        console.log("router called keyword Search", to.params)
        store.dispatch('searchByParams', {"Schlagwort": to.params.keyword});
    }
  })
  
export default router