<template>
  <div class="container">
    <div class="wave">
      <svg viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
        <path d="M0,150 C150,200 350,0 500,100 L500,00 L0,0 Z" style="stroke: #eee; fill: #f5f5f5;"></path>
      </svg>
    </div>
    <div class="header">
        <h1 class="text">
          OPAC <br>
          Theologisches Seminar Erzhausen
        </h1>
        <img class="logo" src="@/assets/bfp-logo.svg" />
      </div>
    <div>
      <SearchForm @search-keyword="filterKeywords" />
      <SearchResultList :filteredKeyword="filteredKeyword" />
    </div>
    <div class="fab-container">
      <button @click="scrolltoTop()" class="button iconbutton btn-color">
        <i class="fa-solid fa-chevron-up"></i>
      </button>
    </div>
  </div>
</template>

<script>
import SearchForm from './SearchForm'
import SearchResultList from './SearchResultList'

export default {
  name: 'Start',
  components: {
    SearchForm,
    SearchResultList
  },
  data() {
    return {
      filteredKeyword: ""
    }
  },
  methods: {
    filterKeywords(keyword) {
      this.filteredKeyword = keyword;
    },
    scrolltoTop() {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-center;
  padding-bottom: 20px;
  .logo {
    width: 280px;
  }
  .text {
    font-weight: 200;
  }
}  

@media (max-width: 900px) {
  .header .logo {
    display: none;
  }
}

.container {
  margin: 10px;
  padding: 10px;
  overflow: auto;
  min-height: 90vh;
}

.wave {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
</style>

<style lang="scss">

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

.btn-color {
  background: $btnBgColorBlue;
  color: $btnTextColor;
}

.btn {
  display: inline-block;
  border: none;
  max-width: 350px;
  padding: 10px 10px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;

  :active, :hover {
    transform: scale(0.98);
  }

  :focus {
    outline: none;
  }
}

.btn-block {
  display: block;
  width: 100%;
  text-align: center;
}

.fab-container{
  position:fixed;
  bottom:20px;
  right:20px;
  z-index: 999;

  button {
    border: 0;
    cursor:pointer;
  }

  .iconbutton{
    width:50px;
    height:50px;
    border-radius: 100%;
    box-shadow: 5px 5px 5px #aaa;
  }

  .button{
    width:60px;
    height:60px;
    display: block;
  }

  .iconbutton i{
    display:flex;
    align-items:center;
    justify-content:center;
    height: 100%;
  }

}
</style>
